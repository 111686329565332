<template>
  <div class="product-set">
    <div v-if="carouselOptions">
      <carousel
        :adjustable-height="carouselOptions.adjustableHeight || false"
        :adjustable-height-easing="
          carouselOptions.adjustableHeightEasing || undefined
        "
        :autoplay="carouselOptions.autoplay || false"
        :autoplay-direction="carouselOptions.autoplayDirection || 'forward'"
        :autoplay-timeout="carouselOptions.autoplayTimeout || 3000"
        :center-mode="carouselOptions.centerMode || false"
        :easing="carouselOptions.easing || 'ease'"
        :loop="carouselOptions.loop || false"
        :max-pagination-dotCount="carouselOptions.maxPaginationDotCount || -1"
        :min-swipe-distance="carouselOptions.minSwipeDistance || 8"
        :mouse-drag="carouselOptions.mouseDrag || true"
        :navigate-to="carouselOptions.navigateTo || 0"
        :navigation-click-target-size="
          carouselOptions.navigationClickTargetSize || 8
        "
        :navigation-enabled="carouselOptions.navigationEnabled || true"
        :navigation-next-label="
          carouselOptions.navigationNextLabel ||
          '<i class=\'fas fa-arrow-right\'></i>'
        "
        :navigation-prev-label="
          carouselOptions.navigationPrevtLabel ||
          '<i class=\'fas fa-arrow-left\'></i>'
        "
        :pagination-active-color="
          carouselOptions.paginationActiveColor || '#000000'
        "
        :pagination-color="carouselOptions.paginationColor || '#efefef'"
        :pagination-enabled="carouselOptions.paginationEnabled || false"
        :pagination-padding="carouselOptions.paginationPadding || 10"
        :pagination-position="carouselOptions.paginationPosition || 'bottom'"
        :pagination-size="carouselOptions.paginationSize || 10"
        :per-page="carouselOptions.perPage || 2"
        :per-page-custom="
          carouselOptions.perPageCustom || [
            [769, 3],
            [600, 2],
          ]
        "
        :resistance-coef="carouselOptions.resistanceCoef || 20"
        :rtl="carouselOptions.rtl || false"
        :scroll-per-page="carouselOptions.scrollPerPage || false"
        :space-padding="carouselOptions.spacePadding || 0"
        :space-padding-max-offset-factor="
          carouselOptions.spacePaddingMaxOffsetFactor || 0
        "
        :speed="carouselOptions.speed || 500"
        :touch-drag="carouselOptions.tagDrag || true"
      >
        <slide
          v-for="product in productSet"
          :key="product.id"
          :class="slideClasses"
        >
          <product-tile
            :is-ragnarok="isRagnarok"
            :product="product"
            :use-loyalty-points="useLoyaltyPoints"
            :disabled="disabled"
            :is-favorite="isFavoriteProduct(product.id)"
            :show-price="showPrice"
            :actionText="actionText"
            :show-add-to-cart="showAddToCart"
            :show-ratings="showRatings"
            :show-modal-on-add-to-cart="showModalOnAddToCart"
            @favorite="favoriteProduct"
          />
        </slide>
      </carousel>
    </div>

    <div
      v-else
      class="columns is-multiline is-mobile"
      :class="{ 'is-centered': isCentered }"
    >
      <div
        class="column product-tile-column"
        :class="columnClasses"
        v-for="product in productTiles"
        :key="product.id"
      >
        <slot v-if="product === 'firstTile'" name="firstTile"></slot>
        <slot v-else-if="product === 'randomTile'" name="randomTile"></slot>
        <slot v-else-if="product === 'tile3'" name="tile3"></slot>
        <slot v-else-if="product === 'tile7'" name="tile7"></slot>
        <product-tile
          v-else
          :is-ragnarok="isRagnarok"
          :product="product"
          :use-loyalty-points="useLoyaltyPoints"
          :disabled="disabled"
          :is-favorite="isFavoriteProduct(product.id)"
          :show-price="showPrice"
          :actionText="actionText"
          :show-add-to-cart="showAddToCart"
          :show-ratings="showRatings"
          :show-modal-on-add-to-cart="showModalOnAddToCart"
          @favorite="favoriteProduct"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductTile from "./ProductTile.vue";
import favorites from "../mixins/favorites";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "ProductSet",

  mixins: [favorites],

  components: {
    "product-tile": ProductTile,
    carousel: Carousel,
    slide: Slide,
  },

  props: {
    filters: { default: null, type: Array },
    skus: { default: null, type: Array },
    products: { default: null, type: Array },
    columnClasses: { default: "is-4" },
    isCentered: { default: false },
    useLoyaltyPoints: { default: false },
    disabled: { default: false },
    isRagnarok: { default: false },
    showPrice: { default: true },
    carouselOptions: { default: null, type: Object },
    slideClasses: { default: "" },
    actionText: { default: null, type: String },
    loading: { default: false, type: Boolean },
    showAddToCart: { default: false },
    showRatings: { default: true },
    showModalOnAddToCart: { default: true }
  },

  data() {
    return {
      productSet: [],
      randomTileIndex: -1,
    };
  },

  computed: {
    productTiles() {
      // Clone the product set so we don't keep adding special tiles to thw source data
      let tiles = this.productSet ? [...this.productSet] : [];

      if (!this.loading) {
        if (this.$slots.firstTile) {
          tiles.splice(0, 0, "firstTile");
        }
  
        if (this.$slots.randomTile && this.randomTileIndex >= 0) {
          tiles.splice(this.randomTileIndex, 0, "randomTile");
        }
  
        if (this.$slots.tile3) {
          tiles.splice(2, 0, "tile3");
        }
  
        if (this.$slots.tile7) {
          tiles.splice(6, 0, "tile7");
        }
      }

      return tiles;
    }
  },

  methods: {
    isFavoriteProduct(productId) {
      // This is here (versus ProductTile) because the favorites mixin is created PER
      // component that uses it - so it was making 24 ajax calls per browse page :/
      return this.isFavorite(productId);
    },

    favoriteProduct(productId) {
      this.favorite(productId);
    },

    loadSkus(skus) {
      let skuList = this.$props.skus.join(",");
      axios
        .get(`/products/by-ids?skus=${skuList}`)
        .then((response) => {
          this.productSet = response.data;
        })
        .catch((error) => {
          this.productSet = [];
        });
    },

    loadSearch(filters) {
      let filterParams = filters
        .map((elem, idx) => {
          return window.encodeURI(`f[${idx}]=${elem[0]}_${elem[1]}`);
        })
        .join("&");

      axios
        .get(`/products/set-of?${filterParams}&sortp=name.keyword&ascp=ASC`)
        .then((response) => {
          this.productSet = response.data;
        })
        .catch((error) => {
          this.productSet = [];
        });
    },

    areArraysSame(array1, array2) {
      let result = false;
      if (array1 === null && array2 === null) {
        result = true;
      } else if (array1 !== null && array2 === null) {
        result = false;
      } else if (array1 === null && array2 !== null) {
        result = false;
      } else {
        // Obviously the arrays need to be in the same order too .. maybe not ideal
        result =
          array1.length === array2.length &&
          array1.every((value, index) => value === array2[index]);
      }
      return result;
    },
  },

  watch: {
    products(newProducts, oldProducts) {
      if (!this.areArraysSame(newProducts, oldProducts)) {
        this.productSet = newProducts;

        if (this.$slots.randomTile) {
          this.randomTileIndex = Math.floor(
            Math.random() * (newProducts.length - 1) + 1
          );
        }
      }
    },

    skus(newSkus, oldSkus) {
      if (!this.areArraysSame(newSkus, oldSkus)) {
        this.loadSkus(newSkus);
      }
    },
  },

  created() {
    if (this.$props.filters) {
      this.loadSearch(this.$props.filters);
    } else if (this.$props.skus) {
      this.loadSkus(this.$props.skus);
    } else if (this.$props.products) {
      this.productSet = this.$props.products;
    }
  },
};
</script>
<template>
  <div class="simple-product-tile" v-if="this.product !== null">
      <div class="product">
          <div class="subtitle">
              {{ this.product.name }}
          </div>
          <div class="image">
              <img :src="product.imageUrl" :alt="product.name"/>
          </div>
          <div class="content">
              <slot name="content"></slot>
              <add-to-cart-button
                  v-show="showAddToCart"
                  :product-id="product.id"
                  :product-name="product.name"
                  :out-of-stock="product.outOfStock"
                  :extended-backorder="product.extendedBackorder">
              </add-to-cart-button>
          </div>
      </div>
  </div>
</template>

<script>
import AddToCartButton from "../AddToCartButton.vue";

export default {
    name: "SimpleProductTile",
    components: {
        'add-to-cart-button': AddToCartButton,
    },

    props: {
        sku: { type: String, default: null },
        showAddToCart: { type: Boolean, default: true }
    },

    data() {
      return {
          product: null
      }
    },

    methods: {
        loadProduct() {
            axios
                .get(`/products/by-ids?skus=${this.sku}`)
                .then((response) => {
                    this.product = response.data[0];
                })
                .catch((error) => {
                    this.product = null;
                    this.$buefy.notification.open({duration: 5000, type: 'is-danger', position: 'is-bottom-right', message: "We're sorry, an error occurred. Please try again.", role: "alert"})
                });
        }
    },

    created() {
        if (this.sku !== null) {
            this.loadProduct();
        }
    }
}
</script>
<template>
  <div class="image-card" :class="{ animated: animated }" :style="cardStyles">
    <div>
      <span class="background-image" role="img" :style="backgroundImageStyles" :aria-label="altText" :aria-hidden="!(!!altText)"></span>
      <!-- Only here for smooth animation -->
    </div>
    <a :href="href"
       :target="target"
       :style="{ padding: cardPadding }"
       class="image-card-link"
       :aria-label="ariaLinkDescription"
       :tabindex="ariaHide ? -1 : 0"
       :role="role"
       :aria-hidden="ariaHide ? true : null"
       :class="{ 'quote-gradient': hasQuote }"
       :aria-description="ariaDescription ? ariaDescription : null"
    >
      <div
          class="tag is-black"
          :style="{ backgroundColor: badgeColor }"
          v-if="badge">
        {{ badge }}
      </div>

      <div class="card-contents" :class="{'is-title-top': isTitleTop}" v-if="layout === 'card'">
        <div v-if="hasQuote" class="image-card--quote">
          <slot name="quote"></slot>
        </div>

        <div class="icon-holder" :class="{ 'tag is-black': iconBadge }" v-if="icon">
          <i :class="icon" :style="iconStyles"></i>
        </div>
        <div aria-hidden="true" class="title is-2" v-if="title" :style="titleStyles" v-html="title"></div>
        <slot></slot>
        <div aria-hidden="true" class="title is-6"
             v-if="subtitle"
             :style="subtitleStyles"
             v-html="subtitle"></div>
      </div>
      <div class="columns banner-contents" v-else-if="layout === 'banner'">
        <div class="column is-narrow icon-container">
          <i :class="icon" :style="iconStyles"></i>
        </div>
        <div class="columns is-vcentered primary-container">
          <div class="column is-4 image-container">
            <img :src="primaryImageUrl" :alt="altText" :aria-hidden="!(!!altText)">
          </div>
          <div class="column">
            <div aria-hidden="true" class="title is-2" v-if="title" :style="titleStyles" v-html="title"></div>
            <div aria-hidden="true" class="title is-6" v-if="subtitle" :style="subtitleStyles" v-html="subtitle"></div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "ImageCardNew",

  props: {
    href: {type: String, default: null},
    background: {type: String, default: null},
    backgroundColor: {type: String, default: "#000000"},
    height: {type: Number, default: 275},
    borderStyle: {type: String, default: ''},
    isFullHeight: {type: Boolean, default: false},
    padding: {type: Number, default: 30},
    overlay: {type: Boolean, default: false},
    overlayGradient: {type: Boolean, default: true},
    overlayGradientOverride: {type: String, default: null},
    badge: {type: String},
    badgeColor: {type: String, default: "#191919"},
    icon: {type: String},
    iconColor: {type: String, default: "#7fe0a7"},
    iconSize: {type: Number, default: 24},
    iconBadge: {type: Boolean, default: false},
    title: {type: String},
    titleSize: {type: Number, default: 24},
    titleColor: {type: String},
    titleWeight: {type: Number, default: 500},
    titlePosition: {type: String, default: "bottom"},
    subtitle: {type: String},
    subtitleSize: {type: Number, default: 14},
    subtitleColor: {type: String},
    animated: {type: Boolean, default: false},
    ariaHide: {type: Boolean, default: false},
    altText: {type: String, default: ""},
    hiddenTitle: {type: String, default: null},
    target:{type: String, default: "_self"},
    layout:{type: String, default: "card"},
    primaryImage:{type: String, default: null},
    ariaDescription:{type: String, default: null}
  },

  data() {
    return {
      baseFontSize: 14,
    };
  },

  computed: {
    isTitleTop() {
      return "top" === this.titlePosition;
    },

    ariaLinkDescription() {
      const description = []

      if (this.title) description.push(this.title);
      if (this.hiddenTitle) description.push(this.hiddenTitle);
      if (this.subtitle) description.push(this.subtitle);
      if (this.badge) description.push(this.badge);

      return description.length > 0 ? description.join(" ") : null;
    },

    cardStyles() {
      const styles = {
        height: this.isFullHeight ? "100%" : this.pxToRem(this.height),
        minHeight: this.pxToRem(this.height)
      };

      if (this.borderStyle) {
        styles.border = this.borderStyle;
      }

      return styles;
    },

    cardPadding() {
      return this.pxToRem(this.padding);
    },

    backgroundImageStyles() {
      let style = {backgroundColor: this.backgroundColor};
      if (this.background) {
        let overlayStyle = "";
        if (this.overlay) {
          overlayStyle = this.overlayGradient
              ? this.overlayGradientOverride
                  ? this.overlayGradientOverride + ", "
                  : "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), "
              : "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ";
        }
        style.backgroundImage = `${overlayStyle} url(${this.$cdn(this.background)})`;
      }
      return style;
    },

    primaryImageUrl() {
      return this.$cdn(this.primaryImage);
    },

    iconStyles() {
      return {
        color: this.iconColor,
        marginRight: 0,
        fontSize: this.pxToRem(this.iconSize),
      };
    },

    titleStyles() {
      return {
        color: this.titleColor
            ? this.titleColor
            : this.overlay
                ? "#ffffff"
                : "#191919",
        fontSize: this.pxToRem(this.titleSize),
        fontWeight: this.titleWeight,
      };
    },

    subtitleStyles() {
      return {
        color: this.subtitleColor
            ? this.subtitleColor
            : this.overlay
                ? "#ffffff"
                : "#191919",
        fontSize: this.pxToRem(this.subtitleSize),
      };
    },

    hasQuote() {
      return !!this.$slots.quote;
    },

    role() {
      let role = null;
      if (this.ariaHide) {
        role = "none";
      } else if (!this.href) {
        role = "button";
      }
      return role;
    }
  },

  methods: {
    pxToRem(px) {
      return `${px / this.baseFontSize}rem`;
    },
  },
};
</script>
<template>
  <div class="add-to-cart-button">
    <cart-modal ref="cartModal"
                :cart-quantity="cartQuantity"
                :cart-product="cartProduct"
                @closed="modalClosed"/>
    <button v-if="!active" :disabled="true" tabindex="0" role="button" class="button is-primary" :class="{'is-large' : isLarge, 'is-fullwidth': isFullWidth, 'is-loading': saving}">
      Unavailable
    </button>
    <button v-else-if="active && canAddToCart"
            :aria-label="ariaLabel"
            tabindex="0"
            role="button"
            :id="id"
            @click.prevent="addToCart"
            @focusout="leaveFocus"
            @focusin="enterFocus"
            class="button"
            :disabled="disabled || saving"
            :class="{'is-primary' : !outOfStock, 'preorder' : outOfStock, 'is-large' : isLarge, 'is-fullwidth': isFullWidth, 'is-loading': saving}">
      <template v-if="showButtonText">
        {{ ctaText }}
      </template>
      <slot></slot>
    </button>
    <a v-else-if="active && !canAddToCart" tabindex="0" role="button" class="button notify-me" :class="{'is-large' : isLarge}" @click.prevent="showForm">Out of Stock &mdash; Notify Me</a>

    <collapse-transition>
      <backorder-notification-form ref="form" v-if="showNotificationForm" @done="hideForm" @cancel="hideForm" v-bind="notificationFormData"></backorder-notification-form>
    </collapse-transition>

  </div>
</template>

<script>
import {CollapseTransition} from "vue2-transitions";
import BackorderNotificationForm from "./BackorderNotificationForm.vue";
import CartModal from "./CartModal.vue";
import store from "../store.js";
import CartService from "../classes/customer/CartService.js";


export default {
  name: 'AddToCartButton',

  components: {
    'collapse-transition': CollapseTransition,
    'backorder-notification-form': BackorderNotificationForm,
    CartModal
  },
  store,

  props: {
    id: {default: null},
    productId: {required: true},
    productName: {required: true},
    outOfStock: {required: true},
    extendedBackorder: {required: true},
    active: {default: true},
    quantity: {default: 1},
    frequency: {default: 0},
    customerEmail: {default: ''},
    isLarge: {default: false},
    isFullWidth: {default: false},
    disabled: {default: false},
    useLoyaltyPoints: {default: false},
    buttonText: {default: "Add to Cart"},
    showButtonText: {default: true},
    showModalOnAddToCart: {default: true}
  },

  data() {
    return {
      saving: false,
      showNotificationForm: false,
      isProfessional: isProfessional,
      cartQuantity: null,
      cartProduct: null,
      cartService: null,
      notificationFormData: {
        productId: this.$props.productId,
        productName: this.$props.productName,
        emailAddress: this.$props.customerEmail
      }
    };
  },

  mounted() {
    this.cartService = new CartService();
  },
  methods: {
    modalClosed(){
      this.$emit('modal-closed');
    },
    addToCart() {
      let qty = this.$props.quantity;
      this.addToCartInternal(qty);
    },

    addToCartInternal(qty) {
        this.saving = true;
        this.cartService.addProduct({id: this.$props.productId, name: this.$props.productName, quantity: qty, frequency: this.$props.frequency, usePoints: this.$props.useLoyaltyPoints})
        .then(response => {
              // Show Modal
              this.cartQuantity = response.lines.reduce((acc, line) => acc + line.quantity, 0);
              this.cartProduct = response.lines.find(line => line.productId === this.productId);

              if (this.showModalOnAddToCart) {
                this.$refs.cartModal.open();
              }
              this.$store.commit("setCartQuantity", this.cartQuantity);

              this.$emit('added', qty);
            })
            .catch(error => {
                this.$buefy.notification.open({duration: 10 * 1000, type: 'is-danger', position: 'is-bottom-right', message: error.msg, role: "alert"});
            })
            .finally(() => {
              this.saving = false;
            });

    },

    showForm(event) {
      let thisComponent = this;

      function setFormPosition() {
        let buttonClientRect = event.target.getBoundingClientRect();
        let formTop = buttonClientRect.top + buttonClientRect.height;
        thisComponent.$refs.form.top = formTop;
      }

      this.showNotificationForm = true;
      this.$nextTick(() => {
        setFormPosition();
      });

      window.onscroll = () => {
        setFormPosition();
      }
    },

    hideForm() {
      this.showNotificationForm = false;
      window.onscroll = undefined;
    },

    leaveFocus(event){
        this.$emit('leaveFocus', event);
    },

    enterFocus(event){
      this.$emit('enterFocus');
    }
  },
  watch: {
    productId(newVal, oldVal) {
      this.notificationFormData.productId = newVal;
    },
    productName(newVal, oldVal) {
      this.notificationFormData.productName = newVal;
    },
    customerEmail(newVal, oldVal) {
      this.notificationFormData.emailAddress = newVal;
    },
  },
  computed: {
    effusioCookieName() {
      return `effusio-notice-${this.productId}`;
    },

    effusioProduct() {
      return this.productName.indexOf("Effusio") > -1;
    },

    ctaText() {
      return this.outOfStock ? 'Out of Stock - Pre-Order' : this.buttonText;
    },

    canAddToCart() {
      return !this.$props.extendedBackorder;
    },

    ariaLabel() {
      return `${this.productName} ${this.ctaText}`;
    }
  }
};
</script>
